import { useEffect, useState } from "react";
import styled from "styled-components";
import { Search, SubText } from "../styles";
import { collection, query, getDocs, orderBy } from "firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../pages/CircularLoader";
import { useMediaQuery } from "@mui/material";
import { useRef } from "react";

const UsersTable = ({ open, id }) => {
  const { singleUser, setSingleUser } = open;
  const { userId, setUserId } = id;
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const mobile = useMediaQuery("(max-width: 900px)");

  const [searchUsers, setSearchUsers] = useState(undefined)

  const userList = [];

  useEffect(() => {
    async function getUsers() {
      const q = query(collection(db, "users"), orderBy('registerDate', "desc"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        userList.push(doc.data());
      });
      setUsers(userList);
    }

    getUsers();
  }, []);

  useEffect(() => {
    if (!users) {
      setIsLoading(true);
    }

    if (users) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [users]);

  function handleClick(id) {
    setSingleUser(true);
    setUserId(id);
  }

  // useEffect(() => {
  //   if (searchRef && !searchRef.current?.value) {
  //     searchUsers(false);
  //   }
  // }, [searchRef.current?.value]);

  function getTime(milliSeconds) {
    const seconds = milliSeconds / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const weeks = days / 7;

    if (seconds > 60 && minutes < 60) {
      return (
        Math.floor(minutes) +
        (Math.floor(minutes) > 1 ? " minutes ago" : " minute ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours < 24) {
      return (
        Math.floor(hours) + (Math.floor(hours) > 1 ? " hours ago" : " hour ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days < 7) {
      return (
        Math.floor(days) + (Math.floor(days) > 1 ? " days ago" : " day ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days > 7) {
      return (
        Math.floor(weeks) + (Math.floor(weeks) > 1 ? " weeks ago" : " week ago")
      );
    }

    return (
      Math.floor(seconds) +
      (Math.floor(seconds) > 1 ? " seconds ago" : " seconds ago")
    );
  }

  const searchRef = useRef()

  function handleUsers(e) {
    const value = e.target.value;
    if (!value) {
      setSearchUsers(false);
    }

    let filter = [];

    Object.values(users).forEach((user) => {
      if (
        String(user.firstname).toLowerCase().includes(String(value).toLowerCase())
      ) {
        filter.push(user);
      } else {
        setSearchUsers(false);
      }
    });
    setSearchUsers(filter);
  }

  return (
    <>

<Search className="searchContainer" style={{marginTop: "16px", maxWidth: !mobile && "360px"}}>
                    <input
                      type="text"
                      placeholder="Search for a user"
                      name="search"
                      onChange={handleUsers}
                      ref={searchRef}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </Search>
 
    <Wrapper>




      <SubText className="top">Users</SubText>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <table className="scrollbar-hidden">
          <thead>
            <tr>
              <th>User</th>
              <th>ID</th>
              <th>Email</th>
              <th>Status</th>
              <th>Last Login</th>
              <th>Registered</th>
              <th>Verification</th>
              <th>Currency</th>
            </tr>
          </thead>
          <tbody>


            {searchUsers && (searchUsers?.map((user) => (
              <tr
                key={user.id}
                className="row"
                onClick={() => handleClick(user.id)}
              >
                <td className="name user">
                  {user.photoURL && (
                    <div style={{ position: "relative" }}>
                      <img src={user.photoURL} alt="user" className="profile" />
                      {user.admin && (
                        <span
                          style={{
                            position: "absolute",
                            bottom: "0",
                            right: "0",
                          }}
                        >
                          <img src="/extraicons/checkmark.svg" alt="admin" />
                        </span>
                      )}
                    </div>
                  )}

                  {!user.photoURL && (
                    <div style={{ position: "relative" }}>
                      <span className="avatar">
                        {user.firstname.slice(0, 1)}
                      </span>

                      {user.admin && (
                        <span
                          style={{
                            position: "absolute",
                            bottom: "-4px",
                            right: "-4px",
                          }}
                        >
                          <img src="/extraicons/checkmark.svg" alt="admin" />
                        </span>
                      )}
                    </div>
                  )}

                  <p>{user.firstname}</p>
                </td>
                <td className="id">
                  <p>{user.id}</p>
                </td>
                <td className="email">
                  <p>{user.email}</p>
                </td>
                <td>
                  {!user.blocked === true && (
                    <button className="approved">
                      <p>Active</p>
                    </button>
                  )}
                  {user.blocked === true && (
                    <button className="declined">
                      <p>Blocked</p>
                    </button>
                  )}
                </td>
                <td>
                  <p>{getTime(new Date() - user.lastLogin.toDate())}</p>
                </td>
                <td>
                    <p>{getTime(new Date() - user.registerDate.toDate())}</p>
                  </td>
                <td>
                  {user.verified && (
                    <button className="approved">
                      <p>Verified</p>
                    </button>
                  )}
                  {!user.verified && (
                    <button className="declined">
                      <p>Unverified</p>
                    </button>
                  )}
                </td>
                <td>USD</td>
              </tr>
            )))
            }

            {
              users && !searchUsers && (users.map((user) => (
                <tr
                  key={user.id}
                  className="row"
                  onClick={() => handleClick(user.id)}
                >
                  <td className="name user">
                    {user.photoURL && (
                      <div style={{ position: "relative" }}>
                        <img src={user.photoURL} alt="user" className="profile" />
                        {user.admin && (
                          <span
                            style={{
                              position: "absolute",
                              bottom: "0",
                              right: "0",
                            }}
                          >
                            <img src="/extraicons/checkmark.svg" alt="admin" />
                          </span>
                        )}
                      </div>
                    )}
  
                    {!user.photoURL && (
                      <div style={{ position: "relative" }}>
                        <span className="avatar">
                          {user.firstname.slice(0, 1)}
                        </span>
  
                        {user.admin && (
                          <span
                            style={{
                              position: "absolute",
                              bottom: "-4px",
                              right: "-4px",
                            }}
                          >
                            <img src="/extraicons/checkmark.svg" alt="admin" />
                          </span>
                        )}
                      </div>
                    )}
  
                    <p>{user.firstname}</p>
                  </td>
                  <td className="id">
                    <p>{user.id}</p>
                  </td>
                  <td className="email">
                    <p>{user.email}</p>
                  </td>
                  <td>
                    {!user.blocked === true && (
                      <button className="approved">
                        <p>Active</p>
                      </button>
                    )}
                    {user.blocked === true && (
                      <button className="declined">
                        <p>Blocked</p>
                      </button>
                    )}
                  </td>
                  <td>
                    <p>{getTime(new Date() - user.lastLogin.toDate())}</p>
                  </td>
                  <td>
                    <p>{getTime(new Date() - user.registerDate.toDate())}</p>
                  </td>
                  <td>
                    {user.verified && (
                      <button className="approved">
                        <p>Verified</p>
                      </button>
                    )}
                    {!user.verified && (
                      <button className="declined">
                        <p>Unverified</p>
                      </button>
                    )}
                  </td>
                  <td>USD</td>
                </tr>
              ))) 
            }
          </tbody>
        </table>
      )}
    </Wrapper>

    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  max-width: 100%;

  .avatar {
    width: 32px;
    place-content: center;
    height: 32px;
    align-items: center;
    /* padding: 0.5rem; */
    background-color: #c4c4c4;
    display: flex;
    color: #131824;
    border-radius: 50%;
  }

  .profile {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }

  .declined {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(230, 75, 96, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #e64b60;
    }
  }

  .approved {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(32, 188, 164, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #20bca4;
    }
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  table {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    max-width: 100%;
    overflow-x: auto;

    .name {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      text-transform: capitalize;

      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .id,
    .email {
      box-sizing: border-box;

      p {
        text-overflow: ellipsis;
        max-width: 85px;
        overflow: hidden;
      }
    }
  }

  thead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  tbody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    display: grid;
    gap: 0.7rem;

    .row {
      border-radius: 4px;
      cursor: pointer;
    }

    .row:hover {
      background-color: #1f273a;
    }
  }

  th,
  td {
    width: 144px;
    text-align: left;
  }

  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    box-sizing: border-box;
  }
`;

export default UsersTable;
