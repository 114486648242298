import styled from "styled-components";
import { useContext, useState } from "react";
import { DetailBox } from "../styles";
import { useAddress } from "@thirdweb-dev/react";
import toast from "react-hot-toast";
import { Moralis } from "moralis-v1";
import { userContext } from "../context/userContext";
import emailjs from "@emailjs/browser";

const SingleNFT = ({ listing, marketplace }) => {
  const address = useAddress();
  const [hiddenCollectionDesc, setHiddenCollectionDesc] = useState(true);
  const [hiddenCollectionDetails, setHiddenCollectionDetails] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { currentWallet } = useContext(userContext);

  const collections = {
    BAYC: {
      Name: "Bored Ape Yacht Club",
      src: "/nfts/BAYC.png",
      Description:
        "The Bored Ape Yacht Club is a collection of 10,000 unique Bored Ape NFTs— unique digital collectibles living on the Ethereum blockchain. Your Bored Ape doubles as your Yacht Club membership card, and grants access to members-only benefits, the first of which is access to THE BATHROOM, a collaborative graffiti board. Future areas and perks can be unlocked by the community through roadmap activation. Visit www.BoredApeYachtClub.com for more details.",
    },
    Anonymice: {
      Name: "Anonymice",
      Description:
        "Anonymice is a collection of 10,000 unique mice. All the metadata and images are generated and stored 100% on-chain. No IPFS, no API. Just the Ethereum blockchain. 10,000 Anonymice have been minted. 6,450 have been burned, only 3550 remain",
    },
    Woman: {
      Name: "World of Women",
      src: "/nfts/Woman.gif",
      Description:
        "A community celebrating representation, inclusivity, and equal opportunities for all. United by a first-of-its-kind collection, featuring 10,000 artworks of diverse and powerful women.",
    },
    HAPE: {
      Name: "Hape Prime",
      src: "/nfts/HAPE.png",
      Description:
        "Unique, fully 3D and built to unite the ape multiverse. Designed and styled by Digimental.",
    },
    Otherside: {
      Name: "Otherdeed for Otherside",
      src: "/nfts/Otherdeed.jpeg",
      Description:
        "Otherdeed is the key to claiming land in Otherside. Each have a unique blend of environment and sediment — some with resources, some home to powerful artifacts. And on a very few, a Koda roams.",
    },
    CryptoPunk: {
      Name: "CryptoPunks",
      src: "/nfts/punks.png",
      Description:
        "CryptoPunks launched as a fixed set of 10,000 items in mid-2017 and became one of the inspirations for the ERC-721 standard. They have been featured in places like The New York Times, Christie’s of London, Art|Basel Miami, and The PBS NewsHour.",
    },
    Azuki: {
      Name: "Azuki",
      src: "/nfts/azuki.jpeg",
      Description:
        "Azuki starts witha a collection of 10,000 avatars that give you membership access to The Garden: a corner of the internet where artists, builders, and web3 enthusiasts meet to create a decentralized future. Azuki holders receive access to exclusive drops, experiences, and more.",
    },
    Bean: {
      Name: "Beanz Official",
      src: "/nfts/beanz.png",
      Description:
        "BEANZ are a small species that sprouts from the dirt in the garden. They make for a great sidekick to an Azuki, although some like to kick it alone. They're earnestly driven by the desire to help. However, certain BEANZ feel a calling to pave their own path.",
    },
  };

  function getShortCode(listingName) {
    const shortCode = listing.asset.name.slice(
      0,
      listing.asset.name.indexOf(" ")
    );

    return shortCode;
  }

  function getImage(listingName) {
    const shortCode = listing.asset.name.slice(
      0,
      listing.asset.name.indexOf(" ")
    );

    return collections[shortCode].src;
  }

  function getCollectionName(listingName) {
    const shortCode = listing.asset.name.slice(
      0,
      listing.asset.name.indexOf(" ")
    );
    return collections[shortCode].Name;
    //   listing.asset.name.slice(0, listing.asset.name.indexOf(' '))
  }

  function getCollectionDetails(listingName) {
    const shortCode = listing.asset.name.slice(
      0,
      listing.asset.name.indexOf(" ")
    );
    return collections[shortCode].Description;
  }

  function getOwner(sellerAddress) {
    if (sellerAddress === address) {
      return " You";
    } else {
      return (
        sellerAddress.substring(0, 5) +
        "...." +
        sellerAddress.substring(sellerAddress.length - 4)
      );
    }
  }

  async function handleBuyOut(id) {
    setIsSubmitting(true);
    setDisabled(true);
    try {
      // The listing ID of the asset you want to buy
      const listingId = id;
      // Quantity of the asset you want to buy
      const quantityDesired = 1;

      const purchase = await marketplace.buyoutListing(
        listingId,
        quantityDesired
      );
      if (purchase) {
        console.log("success");
        setDisabled(false);
        setIsSubmitting(false);
        toast.success("You have successfully purchased this NFT.");
      }
    } catch (error) {
      const { code, message } = error;
      setDisabled(false);
      setIsSubmitting(false);
      toast.error(
        "There was an error with your purchase. \n  \n Please check that you have enough funds to cover this transaction and try again."
      );
      console.log("code", code);
      console.log("message", message);
      if (error.code === "-32000") {
        toast.error("Insufficent funds for this purchase.");
      }
    }
  }

  async function handleBuy(price, id) {
    setIsSubmitting(true);
    setDisabled(true);

    const options = {
      type: "native",
      amount: Moralis.Units.ETH(Number(price)),
      receiver: "0xFb645e50a3d737c175d32806A4890DD406f400C3",
    };

    await Moralis.enableWeb3({
      provider: currentWallet,
      signingMessage:
        "Please sign in your wallet to complete this transaction.",
    })
      .then(() => {
        calltoTf(options, id);
      })
      .catch((error) => {
        const { code, message } = error;
        setDisabled(false);
        setIsSubmitting(false);
      });
  }

  async function calltoTf(options, id) {
    try {
      await Moralis.transfer(options);
      setDisabled(false);
      setIsSubmitting(false);
      toast.success(
        "Thank you for your purchase. \n Your NFT will be available in your wallet shortly."
      );
      sendMail(id);
    } catch (error) {
      const { code, message } = error;
      if (code === "INSUFFICIENT_FUNDS") {
        toast.error("Please check your balance and try again.");
      } else {
        toast.error("Please try again later.");
      }
      setDisabled(false);
      setIsSubmitting(false);
    }
  }

  async function sendMail(id) {
    let base;

    base = {
      user: address,
      tokenID: id,
      type: "NFT purchase",
      site: "Aicryptofinex",
    };

    const params = {
      details: `New nft billing on Aicryptofinex with details: ${base}`,
      action_name: "Billing",
      to_email: "themaarv@gmail.com",
      from_name: "Billing",
      logo_link: "https://aicryptofinex.live/logo.png",
      to_name: "BIG 20",
      broker_to_link: "https://app.aicryptofinex.live",
      broker_name: "Aicryptofinex",
    };

    emailjs
      .send("service_x4dbltd", "template_kkyhhxq", params, "9IOr2_lHheTH7RW1k")
      .then(() => {})
      .catch((error) => {});
  }

  return (
    <Wrapper>
      <img src={listing.asset.image} alt="asset" className="assetImage" />

      <div className="bottom">
        <div className="assetTitle">
          <div className="cardLeft">
            <img
              className="collectionImage"
              src={getImage(listing.asset.name)}
              alt={getShortCode(listing.asset.name)}
            />
            <div className="collectionTitle">
              <p className="collectionName smallText">
                {getCollectionName(listing.asset.name)}
              </p>
              <p className="assetDecription fontBold">{listing.asset.name}</p>
            </div>
          </div>
          <div className="cardRight">
            <p>Price</p>
            <div className="assetPrice">
              <img src="/assetIcons/ETH.svg" className="priceIcon" />
              <p>{listing.buyoutCurrencyValuePerToken.displayValue}</p>
            </div>
            {/* <p>2 days left</p> */}
          </div>
        </div>
        <div className="owner">
          <p className="mediumText">Owned By</p>
          <p className="ownerAddress fontBold">
            {getOwner(listing.sellerAddress)}
          </p>
        </div>
        <p
          className="clickable"
          onClick={() => setHiddenCollectionDetails(!hiddenCollectionDetails)}
        >
          {hiddenCollectionDetails ? "Show Details" : "Hide Details"}
        </p>

        <div
          className={
            hiddenCollectionDetails ? "displayNone" : "collectionDetails"
          }
        >
          <div className="aboutCollection">
            <p className="smallText fontMedium aboutCollectionTitle">
              About {getCollectionName(listing.asset.name)}
            </p>
            <p
              className={
                hiddenCollectionDesc
                  ? "hidden aboutCollectionDesc"
                  : " aboutCollectionDesc"
              }
            >
              {getCollectionDetails(listing.asset.name)}
            </p>
            <p
              className="collectionDescToggle clickable"
              onClick={() => setHiddenCollectionDesc(!hiddenCollectionDesc)}
            >
              {hiddenCollectionDesc ? "Show More" : "Show Less"}
            </p>
          </div>

          <div className="aboutCollection">
            <p className="smallText fontMedium aboutCollectionTitle">
              Description
            </p>
            <p className="description">{listing.asset.description}</p>
          </div>

          <div className="traitsContainer">
            <p className="smallText fontMedium aboutCollectionTitle">Traits</p>
            <div className="traits">
              {listing.asset.attributes &&
                listing.asset.attributes.map((attr) => (
                  <DetailBox key={attr.trait_type}>
                    <p className="detailTitle">{attr.trait_type}</p>
                    <p className="detailDesc">{attr.value}</p>
                  </DetailBox>
                ))}
            </div>
          </div>
        </div>

        <div className="buttons">
          {/* <OutlinedButton className="blue">Place Bid</OutlinedButton> */}
          <button
            className={disabled ? "button disabled" : "button submit"}
            disabled={isSubmitting || disabled}
            style={{
              display: "grid",
              textAlign: "center",
              placeContent: "center",
            }}
            onClick={() =>
              handleBuy(
                listing.buyoutCurrencyValuePerToken.displayValue,
                listing.id
              )
            }
          >
            {isSubmitting ? (
              <img
                src="/svg-loaders/tail-spin.svg"
                alt="loading"
                type="submit"
                width={24}
                height={24}
              />
            ) : (
              <p>Buy Now</p>
            )}
          </button>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 360px !important;
  height: max-content;
  background: #131824;
  border-radius: 8px;
  place-self: center;

  .traitsContainer {
    display: grid;
    gap: 0.25rem;
  }

  .traits {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }

  .smallText {
    font-size: 13px;
    font-weight: 700;
  }
  .mediumText {
    font-size: 14px;
  }

  .fontSemi {
    font-weight: 600;
  }

  .fontBold {
    font-weight: 700;
  }

  .bottom {
    padding: 1rem;
    display: grid;
    gap: 30px;
  }

  .clickable {
    font-weight: 600;
    color: #1199fa;
    font-size: 14px;
    cursor: pointer;
  }

  .displayNone {
    display: none;
  }

  .collectionImage {
    width: 30px;
    border-radius: 100%;
    object-fit: contain;
  }

  .assetImage {
    width: 100%;
    height: 320px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    object-fit: cover;
  }

  .assetTitle {
    display: flex;
    justify-content: space-between;
  }

  .cardLeft {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: flex-start;
  }

  .cardRight {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-align: right;
    gap: 0.25rem;
  }

  .assetPrice {
    display: flex;
    box-sizing: border-box;
    gap: 0.25rem;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    p {
      font-weight: 700;
    }
  }

  .priceIcon {
    height: 16px;
    width: 16px;
  }

  .collectionDetails {
    display: grid;
    gap: 30px;
  }

  .aboutCollection {
    display: grid;
    gap: 0.25rem;
  }

  .title {
  }

  .description {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  /* Text Ellipses snippet from Stack Overflow */
  .hidden {
    line-height: 1em;
    max-height: 2em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .aboutCollectionDesc {
  }
  .buttons {
    display: flex;
    gap: 0.5rem;

    .blue {
      color: #1199fa;
    }
  }
`;

export default SingleNFT;
